import React from "react"
import './footer.css';

export const Footer = () => {
  return (
    <div className="FooterClass">
    <p>
      © Copyright Sweetest Interactive Ent.
    </p>
    </div>
  )
}
